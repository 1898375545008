import React, { Component } from 'react';
import MessageBox from './MessageBox/MessageBox';

class Contact extends Component {

    render () {
        return (
            <div>
                <MessageBox/>
            </div>
        )
    }
}

export default Contact
