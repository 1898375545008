import React, { Component } from 'react';
import { MenuItems } from "./MenuItems";
import { Button } from "../Button/Button"
import './Navbar.css';

class Navbar extends Component {

    state = { clicked: false };
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked }) //flip clicked state
    }

    render() {
        return (
            <nav className="NavbarItems">
                <h1
                    className="navbar-logo"
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/';
                    }}>Matia Raspopovic
                </h1>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>

                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}> {item.title}</a>
                            </li>
                        )
                    })}
                </ul>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/contact';
                    }}>Contact
                </Button>
            </nav>
        )
    }
}

export default Navbar
