export const SocialItems = [
    {
        url: 'mailto: matia@raspopovic.ca',
        cName: 'far fa-envelope'
    },
    {
        url: 'https://LinkedIn.com/in/matiaraspopovic',
        cName: 'fab fa-linkedin'
    },
    {
        url: 'https://github.com/matia-R',
        cName: 'fab fa-github'
    }
]